<template>
  <div style="background: white">
    <!-- <HeaderBar /> -->
    <v-sheet class="overflow-hidden">
      <Loading />
      <v-container fluid id="sub-bar">
        <v-row>
          <v-col cols="11">
            <v-btn text @click="showMenu">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <!-- $t("label.画面名") -->
            <div id="lbl-screen-name">{{ $t("btn.btn_import") }}</div>
          </v-col>
          <v-col cols="1">
            <v-btn text id="btn-logout" class="menu-btn" @click="logout">
              <img src="../../assets/img/logout_menu_icon.png" />ログアウト</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid>
        <!-- 検索項目 -->
        <v-row>
          <v-col cols="12" />
        </v-row>
        <v-row>
          <!-- プルダウン1 -->
          <v-col cols="2">
            <v-row>
              <v-col cols="11">
                <v-autocomplete
                  dense
                  ref="country"
                  v-model="xxxxxxxxx1Selected"
                  :items="dummy_xxx1List"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                ></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- テキストボックス1 -->
          <v-col cols="2">
            <v-row>
              <v-col xl="11">
                <v-text-field
                  dense
                  outlined
                  v-model="txt_yyyyyyyyy1"
                  maxlength="10"
                  :label="$t('label.lbl_yyyyyyyyy1')"
                  clear-icon="mdi-close-circle"
                  clearable
                />
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
            </v-row>
          </v-col>
          <!-- 日付 -->
          <v-col cols="3">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  v-model="xxxxxxxDate"
                  class="txt-single"
                  :label="$t('label.lbl_xxxxxxxDate')"
                  @change="changeDate"
                  :rules="[rules.yyyymmdd]"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-row><span class="require">*</span> </v-row>
              </v-col>
              <v-col cols="5">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text><v-icon>mdi-calendar</v-icon></v-btn>
                  </template>
                  <v-date-picker
                    v-model="arrivalScheduleDateCal"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn id="btn-yesterday" class="day-ctrl-btn" @click="prevDate" text>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-tomorow" class="day-ctrl-btn" @click="nextDate" text>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <!-- 取込ファイル -->
          <v-col cols="3">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  prepend-icon=""
                  :label="$t('label.lbl_importFile')"
                  outlined
                  dense
                  append-outer-icon="mdi-paperclip"
                  @click:append-outer="handleClickAppendOuter"
                  ref="vFileInput"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <!-- 取込ボタン -->
          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-btn color="primary" class="two-left-btn" @click="search">{{
                  $t("btn.btn_import")
                }}</v-btn>
              </v-col>
              <v-col cols="4" />
              <v-col cols="1">
                <!-- 取込確定ボタン 2個以上のボタンのclassは異なるので要注意-->
                <v-btn
                  color="primary"
                  id="btn-pack"
                  class="two-right2-btn"
                  value="1"
                  @click="sendData"
                >
                  {{ $t("btn.btn_importConfirm") }}
                </v-btn>
              </v-col>
              <v-col cols="1">
                <!-- 取込取消ボタン-->
                <v-btn
                  color="primary"
                  id="btn-pack"
                  class="two-right1-btn"
                  value="1"
                  @click="sendData"
                >
                  {{ $t("btn.btn_importCancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-row>
            <v-col cols="6" />
            <v-col cols="6">
              <v-row>
                <v-col cols="7" />
                <v-col cols="2">
                  <div class="paging-label">{{ $t("label.lbl_itemsPerPage") }}</div>
                </v-col>
                <v-col cols="1">
                  <v-btn-toggle
                    v-model="itemsPerPage"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" small>
                      {{ $t("10") }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" small>
                      {{ $t("50") }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" small>
                      {{ $t("100") }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- ページング数 -->
        </v-row>
      </v-container>
      <!-- データテーブル 表の高さ変えるところ→height="500px"-->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          disable-filtering
          disable-sort
          :hide-default-footer="true"
          height="565px"
          @page-count="pageCount = $event"
        >
          <template v-slot:item.productCd="{ item }">
            <div>{{ item.productCd }}</div>
            <div>{{ item.productNm }}</div>
          </template>
          <template v-slot:item.accInfos="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div class="text-truncate" style="max-width: 200px" v-bind="attrs" v-on="on">
                  {{ item.accInfos }}
                </div>
              </template>
              <span>{{ item.accInfos }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.location="{ item }">
            <v-btn small @click="addLocation(item)"> {{ $t("btn.btn_insert") }} </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
        <v-col cols="12" />
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
//import { appConfig } from "../assets/scripts/js/AppConfig";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
//import { messsageUtil } from "../assets/scripts/js/MesssageUtil";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";
// import HeaderBar from "@/components/HeaderBar.vue";
export default {
  name: "import",
  components: {
    // HeaderBar,
    Loading,
    SimpleDialog,
    sideMenu,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    direction: "top",
    transition: "slied-y-transition",
    locInfo: {
      locProductCd: "",
      warehouse: "",
      location: "",
      productCd: "",
      qty: 0,
      init: false,
    },
    fab: false,
    hidden: false,
    tabs: null,
    // Xxxxx日付
    arrivalScheduleDateCal: "",
    xxxxxxxDate: "",
    // 有償/無償
    isPaid: "1",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    itemsPerPage: "10",
    // 取込ファイル
    importSelected: "all",
    // プルダウン1
    xxxxxxxxx1Selected: "all",
    // メニュー
    openMenu: null,
    // テキストボックス1.
    txt_yyyyyyyyy1: "",
    // カレンダー
    dateMenu: false,
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },

    // ヘッダ
    headerItems: [
      {
        text: i18n.tc("label.lbl_productCodeName"),
        class: "listHeader",
        value: "productNm",
        width: "10%",

        sortable: false,
      },
      {
        text: "製品付帯",
        value: "productAcc",
        width: "10%",

        sortable: false,
      },
      { text: "入荷予定数", value: "productQty", width: "10%", align: "right", sortable: false },
      { text: "ケース（荷姿）数", value: "caseSu", width: "10%", align: "right" },
      { text: "ボール（中箱）数", value: "ballSu", width: "10%", align: "right" },
      { text: "バラ（端数）", value: "baraSu", width: "10%", align: "right" },
      { text: "ロット", value: "lot", width: "10%" },
      { text: "賞味期限", value: "expireDate", width: "10%" },
      { text: "付帯情報", value: "accInfos", width: "10%" },
    ],
    inputList: [],
    dummy_xxx1List: [
      { text: "ALL", value: "all" },
      { text: "プルダウンA1", value: "supplier1" },
      { text: "プルダウンA2", value: "supplier2" },
      { text: "プルダウンA3", value: "supplier3" },
      { text: "プルダウンA4", value: "supplier4" },
      { text: "プルダウンA5", value: "supplier5" },
    ],
    dummy_importList: [
      { text: "ALL", value: "all" },
      { text: "プルダウンB1", value: "arrival1" },
      { text: "プルダウンB2", value: "arrival2" },
      { text: "プルダウンB3", value: "arrival3" },
      { text: "プルダウンB4", value: "arrival4" },
      { text: "プルダウンB5", value: "arrival5" },
    ],
    dummy_input: {
      productNm: "製品名",
      productCd: "a",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    defaultItem: {
      productNm: "",
      productAcc: "製品付帯",
      productQty: "0",
      caseSu: "0",
      ballSu: "0",
      baraSu: "0",
      lot: "lot",
      expireDate: "2022/01/13",
    },
    productList: [
      { text: "", value: "" },
      { text: "新製品", value: "product0" },
      { text: "製品1", value: "product1" },
      { text: "製品2", value: "product2" },
      { text: "製品3", value: "product3" },
      { text: "製品4", value: "product4" },
    ],
    rules: {
      yyyymmdd: (value) => dateTimeHelper.validDate(value) || "日付が間違っています",
    },
  }),
  methods: {
    // 検索ボタン
    search() {
      for (var i = 0; i < 100; i++) {
        var accInfos = [];
        var data = {
          productNm: "productNm" + i,
          productCd: "productCd" + i,
          productAcc: "productAcc" + i,
          productQty: i,
          caseSu: i,
          ballSu: i * 10,
          baraSu: i * 100,
          lot: "lot" + i,
          expireDate: "expireDate",
          accInfo01: "info01_" + i,
          accInfo02: "info02_" + i,
          accInfo03: "info03_" + i,
          accInfo04: "info04_" + i,
          accInfo05: "info05_" + i,
          accInfo06: "info06_" + i,
          accInfo07: "info07_" + i,
          accInfo08: "info08_" + i,
          accInfo09: "info09_" + i,
          accInfo10: "info10_" + i,
          accInfo11: "info11_" + i,
          accInfo12: "info12_" + i,
          accInfo13: "info13_" + i,
          accInfo14: "info14_" + i,
          accInfo15: "info15_" + i,
          accInfo16: "info16_" + i,
          accInfo17: "info17_" + i,
          accInfo18: "info18_" + i,
          accInfo19: "info19_" + i,
          accInfo20: "info20_" + i,
          accInfos: "",
        };
        for (var j = 1; j <= 20; j++) {
          var idx = j < 10 ? "0" + j : j;
          accInfos.push(data["accInfo" + idx]);
        }
        data.accInfos = accInfos.join("\n");
        this.inputList.push(data);
      }
    },
    // 初期化
    init() {
      this.importSelected = "";
      this.xxxxxxxxx1Selected = "";
      this.txt_yyyyyyyyy1 = "";
      this.inputList = [];
      this.makeDummy();
      this.nowDateReset();
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 与えられた日付から年月日を文字列で返します。
     */
    dateCalc(date) {
      const dt = new Date(date);
      return dt.toISOString().substr(0, 10);
    },
    /**
     * カレンダコンポーネントの日付はyyyy-MM-ddのためyyyy/MM/ddに変換する。
     */
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}/${month}/${day}`;
    },

    changeDate() {
      if (this.xxxxxxxDate.length == 8) {
        var dt = this.xxxxxxxDate;
        this.xxxxxxxDate = dt.substr(0, 4) + "/" + dt.substr(4, 2) + "/" + dt.substr(6, 2);
      }
      dateTimeHelper.validDate(this.xxxxxxxDate);
    },
    //formValidation() {
    // this.$refs.searchForm.validate();
    //},
    handleClickAppendOuter() {
      this.$refs.vFileInput.$el.querySelector("input").click();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    arrivalScheduleDateCal: function () {
      var aa = this.dateCalc(dateTimeHelper.convertJstDateTime(this.arrivalScheduleDateCal));
      this.xxxxxxxDate = this.formatDate(aa);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.list-single ::v-deep,
.txt-single ::v-deep {
  padding-top: 0;
  padding-right: 0;
  font-size: large;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
